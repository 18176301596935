import { useCallback } from 'react'
import logger from 'logger'
import { useApolloClient } from 'apollo-client'
import { ServerError, UserError } from 'helpers'

import addressValidateQuery from './graph/addressValidateNew.graphql'


const useShippingAddressValidateNew = () => {
  const apolloClient = useApolloClient()

  return useCallback(async (enteredValues: UserModule.ShippingFormFields) => {
    const { data } = await apolloClient.mutate({
      mutation: addressValidateQuery,
      variables: {
        inputNew: {
          address: {
            firstName: enteredValues.firstName,
            lastName: enteredValues.lastName,
            country: enteredValues.country,
            city: enteredValues.city,
            region: enteredValues.region,
            postalCode: enteredValues.postalCode,
            street1: enteredValues.street1,
            street2: enteredValues.street2,
            phone: enteredValues.phone,
          },
          apiVersion: 'V2',
        },
      },
      fetchPolicy: 'no-cache',
    })

    const { error } = data.addressValidate

    logger.info({
      response: data.addressValidate,
    }, `[Address validation] address is ${!error ? 'valid' : 'invalid'}`)

    // TODO: Implement AmbiguousAddressError suggestion when loqate starts to give valid results
    // Address is not found, but we give opportunity to confirm the address manually
    if (error?.__typename === 'AddressValidationError' && error?.addressValidationErrorCode === 'ADDRESS_NOT_FOUND') {
      return {
        isValid: false,
      }
    }

    // address error (not found)
    if (error?.__typename === 'AddressValidationError') {
      throw new UserError(error)
    }

    // server error
    if (error) {
      throw new ServerError(error, 'Address validation error')
    }

    // address is correct and validated
    const { data: { address, isPrimaryAddress } } = data.addressValidate

    return {
      isPrimaryAddress,
      isValid: true,
      // we do this to remove all extra attributes from the response too
      recommendedValues: {
        firstName: enteredValues.firstName,
        lastName: enteredValues.lastName,
        country: address.country,
        city: address.city,
        region: address.region,
        postalCode: address.postalCode,
        street1: address.street1,
        street2: address.street2 || enteredValues.street2,
        phone: enteredValues.phone,
      },
    }
  }, [ apolloClient ])
}


export default useShippingAddressValidateNew

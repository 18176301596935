import { useLazyQuery } from 'apollo-client'

import { type AddressSuggestionType } from 'typings/graphql'

import addressAutocompleteQuery from './graph/addressAutocomplete.graphql'


export type AddressSuggestion = {
  type: AddressSuggestionType | null
  placeId: string
  mainText: string
  highlight: string | null
  secondaryText: string
}

const useAddressSuggestions = () => {
  const [ getAddressSuggestions, { data, isFetching } ] = useLazyQuery(addressAutocompleteQuery)

  const addressSuggestions = data?.addressAutocomplete?.data

  return {
    isFetching,
    addressSuggestions,
    getAddressSuggestions,
  }
}

export default useAddressSuggestions

import { useCallback } from 'react'
import { constants, ValidationError } from 'helpers'
import { useFt } from 'hooks'
import { openModal } from 'modals'


const openCotyModal = (props: ModalsRegistry['cotyModal']) =>
  new Promise<boolean>((resolve, reject) => {
    const { oneClickPurchase, products, onFinalizeOrder } = props

    openModal('cotyModal', {
      oneClickPurchase,
      products,
      onFinalizeOrder: () => {
        if (typeof onFinalizeOrder === 'function') {
          onFinalizeOrder()
        }

        resolve(true)
      },
      onClose: () => {
        reject(new ValidationError(null, 'Coty products can\'t be delivered'))
      },
    })
  })

type CotyAddressValidationProps = {
  oneClickPurchase?: boolean
  products: ProductFragment.Minimal[]
}

const useCotyAddressValidation = () => {
  const isCotyEnabled = useFt(constants.features.cotyNYNJ)

  return useCallback(async ({ oneClickPurchase, products }: CotyAddressValidationProps) => {
    if (isCotyEnabled) {
      return openCotyModal({ oneClickPurchase, products })
    }
  }, [ isCotyEnabled ])
}

export default useCotyAddressValidation

import { useQuery } from 'apollo-client'
import countryListQuery from './graph/countryList.graphql'


const useCountryList = ({ withRegions = true, skip = false } = {}) => {
  const { data, isFetching } = useQuery(countryListQuery, {
    fetchPolicy: 'cache-first',
    variables: {
      withRegions,
    },
    skip,
  })

  return {
    countryList: data?.countryList?.data,
    isFetching,
  }
}


export default useCountryList

import { useCallback } from 'react'
import { useMutation } from 'apollo-client'
import { GraphQLError, UserError } from 'helpers'

import addressAddQuery, { type AddressAddVariables } from './graph/addressAdd.graphql'


const useShippingAddressAdd = () => {
  const [ mutate, { isFetching, client } ] = useMutation(addressAddQuery)

  const submit = useCallback(async (input: AddressAddVariables['input']) => {
    const result = await mutate({
      variables: {
        input,
      },
    })

    if (result.errors) {
      throw new GraphQLError(result.errors)
    }

    const { data, error } = result.data.addressAdd

    if (error) {
      throw new UserError(error)
    }

    // cart depends on shipping address
    client.cache.evict({ id: 'UserData:{}', fieldName: 'cart' })

    return data
  }, [ mutate, client ])

  return [
    submit,
    {
      isFetching,
    },
  ] as const
}


export default useShippingAddressAdd

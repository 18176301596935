import { useCallback, useEffect } from 'react'
import { openModal, preloadModal } from 'modals'
import { ValidationError } from 'helpers'
import useShippingAddressValidate from './useShippingAddressValidate'


type ValidateShippingAddressResult = {
  validatedAddressValues: UserModule.ShippingFormFields
  validationType: Analytics.ValidationType
}

const openAddressVerificationModal = (props: ModalsRegistry['addressVerificationModal']) =>
  new Promise<{ selectedValues: UserModule.ShippingFormFields, selectedType: string }>((resolve, reject) => {
    const { enteredValues, recommendedValues, onChoose } = props

    openModal('addressVerificationModal', {
      enteredValues,
      recommendedValues,
      onChoose: (result) => {
        if (typeof onChoose === 'function') {
          onChoose(result)
        }

        resolve(result)
      },
      onClose: () => {
        reject(new ValidationError(null, 'No address was selected'))
      },
    })
  })

// validates and asks user about recommended values if needed
const useShippingAddressValidateAndVerify = () => {
  const validateShippingAddress = useShippingAddressValidate()

  useEffect(() => {
    void preloadModal('verifyShippingAddressModal')
  }, [])

  return useCallback(async (enteredValues: UserModule.ShippingFormFields): Promise<ValidateShippingAddressResult> => {
    let validatedAddressValues = enteredValues
    let validationType: Analytics.ValidationType = 'without validation popup'

    let { isValid, recommendedValues } = await validateShippingAddress(enteredValues)

    if (!isValid) {
      const { selectedValues, selectedType } = await openAddressVerificationModal({
        enteredValues,
        recommendedValues,
      })

      validatedAddressValues = selectedValues
      validationType = selectedType as Analytics.ValidationType
    }
    else if (recommendedValues) {
      validatedAddressValues = recommendedValues
      validationType = 'automatically validated'
    }

    return {
      // to keep entered custom values
      validatedAddressValues: {
        ...enteredValues,
        ...validatedAddressValues,
      },
      validationType,
    }
  }, [ validateShippingAddress ])
}


export default useShippingAddressValidateAndVerify

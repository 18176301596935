import { useMemo } from 'react'
import { useLazyQuery } from 'apollo-client'

import addressAutocompleteDetailsQuery from './graph/addressDetails.graphql'


const useAddressDetails = () => {
  const [ getAutocompleteDetails, { data } ] = useLazyQuery(addressAutocompleteDetailsQuery)

  const autocompleteDetails = useMemo(() => data?.addressAutocompleteDetails?.data, [ data ])

  return {
    autocompleteDetails,
    getAutocompleteDetails,
  }
}

export default useAddressDetails

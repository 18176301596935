import { useQuery } from 'apollo-client'

import shippingAddressesQuery, { type ShippingAddressesPayload } from './graph/shippingAddresses.graphql'


// make all fields required because in the schema they are optional
type ShippingAddress = Required<ShippingAddressesPayload['currentUser']['data']['shippingAddresses'][number]>

// returns all saved shipping addresses, if you need the current one use addressInfo from the user
const useShippingAddresses = () => {
  const { data, isFetching, error } = useQuery(shippingAddressesQuery, {
    fetchPolicy: 'cache-first',
    ssr: false,
  })

  const shippingAddresses = data?.currentUser?.data?.shippingAddresses as ShippingAddress[]

  return {
    shippingAddresses,
    primaryShippingAddress: shippingAddresses?.find(({ primary }) => primary),
    isFetching,
    error,
  }
}


export default useShippingAddresses
